import React, { useEffect } from "react";
import "./dashboard.scoped.scss";
import routerProvider, { useParams } from "@pankod/refine-react-router-v6";
import logo from "assets/img/logologo.png";
import marshallLogo from "assets/img/marshall.png";
import { useMe } from "context/MeContext";
import Lottie from "react-lottie-player";
import lottieJson1 from "./Lottie/lottie1.json";
import lottieJson2 from "./Lottie/lottie2.json";
import lottieJson3 from "./Lottie/lottie3.json";
import lottieJson4 from "./Lottie/lottie4.json";
import lottieJson5 from "./Lottie/lottie5.json";
import lottieJson7 from "./Lottie/lottie7.json";
import { alignmentTextToCss, config } from "config";
import { css } from "@emotion/react";
import { useCompany } from "context/CompanyContext";
import { generateURL } from "utils/generateURL";

import actionPlanBg from "assets/img/Effect.svg";
import EmployerSpace from "components/EmployerSpace";
import { useList } from "@pankod/refine-core";

const { Link } = routerProvider;
const Dashboard: React.FC = () => {
  const { path } = useParams();
  const [isPlay, setIsPlay] = React.useState(false);
  const { refetch, me: user, company, myHRA } = useMe();
  const { path: company_path } = useCompany();
  const { employer, brand, homepage } = useCompany();

  useEffect(() => {
    // Add the class when the component is mounted
    document.body.classList.add("dashboard");

    // Remove the class when the component is unmounted
    return () => {
      document.body.classList.remove("dashboard");
    };
  }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount

  React.useEffect(() => {
    // check if users have not action tracking then system will create a tracking for them.
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (path || !company) return;
    window.history.replaceState(null, "", company?.path);
  }, [path, company]);

  const position = company?.portfolio?.banner?.position?.split("-");
  const horizontal = position?.[1] as any;
  const vertical = position?.[0] as any;
  const _logo = company?.portfolio?.logo ? config.ASSET_URL + company?.portfolio?.logo : logo;
  const banner = company?.portfolio?.banner?.banner_url;
  const bannerRes = company?.portfolio?.banner?.responsiveBanner;

  const { data } = useList({
    resource: "agg-scores",
  });
  const aggScores = data?.data;
  console.log({ aggScores });

  const arr = [
    {
      animation: (
        <Lottie
          speed={1.5}
          loop={0}
          animationData={lottieJson1}
          play
          style={{ width: 1000, height: 1000 }}
          className="lottie"
          onComplete={() => {
            setIsPlay(false);
          }}
        />
      ),
      value: "lottie1",
    },
    {
      animation: (
        <Lottie
          speed={1.5}
          loop={0}
          animationData={lottieJson2}
          play
          // style={{ width: 1000, height: 1000 }}
          className="lottie lottieCheck"
          onComplete={() => {
            setIsPlay(false);
          }}
        />
      ),
      value: "lottie2",
    },
    {
      animation: (
        <Lottie
          speed={2.5}
          loop={0}
          animationData={lottieJson3}
          play
          // style={{ width: 1000, height: 1000 }}
          className="lottie lottieBear"
          onComplete={() => {
            setIsPlay(false);
          }}
        />
      ),
      value: "lottie3",
    },
    {
      animation: (
        <Lottie
          speed={1.5}
          loop={0}
          animationData={lottieJson4}
          play
          // style={{ width: 1000, height: 1000 }}
          className="lottie lottieCheck "
          onComplete={() => {
            setIsPlay(false);
          }}
        />
      ),
      value: "lottie4",
    },
    {
      animation: (
        <Lottie
          speed={1.5}
          loop={0}
          animationData={lottieJson5}
          play
          // style={{ width: 1000, height: 1000 }}
          className="lottie lottieCheck "
          onComplete={() => {
            setIsPlay(false);
          }}
        />
      ),
      value: "lottie5",
    },
    {
      animation: (
        <Lottie
          speed={2}
          loop={0}
          animationData={lottieJson7}
          play
          // style={{ width: 1000, height: 1000 }}
          className="lottie lottieCheck "
          onComplete={() => {
            setIsPlay(false);
          }}
        />
      ),
      value: "lottie7",
    },
  ];
  const icon_settings = company?.icon_setting?.settings || [];

  function randomLottie() {
    const new_arr = arr.filter((item) => icon_settings.includes(item.value));
    // if (new_arr.length === 0) return arr[0].animation;
    if (new_arr.length === 0) return 0;
    return new_arr[Math.floor(Math.random() * new_arr.length)].animation;
  }
  console.log("hra", typeof myHRA);

  return (
    <div className="dashboard-page pageload" id="sd">
      {/* <h1>BANNER</h1> */}
      <div
        className="banner"
        css={css`
          background: url("${generateURL(banner)} ") no-repeat bottom center/cover;
          height: 80vh;
          margin: 0 auto;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          z-index: 1;
          @media (max-width: 660px) {
            background: url("${generateURL(bannerRes)}") no-repeat top center/cover !important;
            margin-top: 30px;
            height: 70vh !important;
            width: 100% !important;
            z-index: 0;
          }
        `}
      >
        <div
          className="banner-container container flex flex-col"
          css={css`
            // height: calc(100% - 12%);
            align-items: ${alignmentTextToCss[horizontal]};
            justify-content: ${alignmentTextToCss[vertical]};
            @media (min-width: 578px) and (max-width: 991px) {
              width: 100% !important;
            }
          `}
        >
          <div
            className="school-title"
            css={css`
              color: ${employer?.color};
              font-size: ${employer?.font_size}px !important;
            `}
          >
            {employer?.name}
          </div>

          {company_path !== "marshall" ? (
            ""
          ) : (
            <img
              src={marshallLogo}
              alt=""
              css={css`
                width: 500px !important;
                margin: auto;
                @media screen and (max-width: 768px) {
                  width: 300px !important;
                  background-color: rgba(255, 255, 255, 0.4) !important;
                  border-radius: 10px;
                }
              `}
            />
          )}
          <h3
            className="dashboard-slogan"
            css={css`
              color: ${employer?.tagline_color} !important;
              font-size: ${employer?.tagline_font_size}px !important;
              text-align: ${horizontal};
              @media screen and (max-width: 768px) {
                margin-top: 10px;
                font-size: 22px !important;
                letter-spacing: 0.5px !important;
                line-height: 25px !important;
                width: 100% !important;
              }
              ${company_path !== "goodlife"
                ? ""
                : css`
                    text-shadow:
                      1px 1px 2px #8c2c2d,
                      0 0 1em white,
                      0 0 0.2em white;
                  `}
            `}
          >
            {employer?.tagline}
          </h3>

          <Link to={`${company_path}/contact`}>
            <button
              className="button-find"
              css={css`
                color: ${employer?.btn_contact_us_color} !important;
                border: 1px solid ${employer?.btn_contact_us_color};
                z-index: 100;
                @media screen and (max-width: 898px) {
                  display: none !important;
                }
                ${company_path === "marshall"
                  ? "background-color: rgba(34, 21, 95, 0.5);"
                  : "background-color: transparent;"}
                &:hover {
                  transition: 0.4s ease-in;
                  color: ${brand?.color_primary} !important;
                  background-color: rgba(255, 255, 255, 0.3);
                }
              `}
            >
              Contact Us
            </button>
          </Link>
        </div>
      </div>

      <div>
        <div className="charts-content" id="sd">
          <div className="dashboard-maincontent">
            <div className="container">
              {/* =====INTRO===== */}
              <div className="j-between dashboard-intro box-format">
                <div className="intro-top">
                  <h2>
                    HI, <span className="user-name">{user?.firstname || user?.email}</span>!
                  </h2>
                  <p>Welcome to the GoodLife platform, your home for wellness!</p>
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href="https://monumenthealth.net/goodlife-tutorials/"
                    css={css`
                      font-style: italic;
                      color: #999999;
                      &:hover {
                        color: var(--c-orange) !important;
                      }
                    `}
                  >
                    Click here to watch a tutorial and enhance your understanding of the dashboard
                  </a>
                </div>
                <div className="logo">
                  <img src={_logo} alt="logo" />
                </div>
              </div>
              {/* =====ADMIN GENERATED===== */}
              <div className="action-plan-title">
                <div className="line"></div>
                <div className="title">ACTION PLAN</div>
                <div className="line"></div>
              </div>
              <div
                className="admin-generated"
                css={css`
                  text-align: center;
                  .admin-generated-title {
                    font-size: 40px;
                    font-weight: 650;
                    color: ${brand?.color_primary};
                  }
                  .admin-generated-slogan {
                    font-size: 17px;
                    width: 50%;
                    margin: 30px auto 50px auto;
                  }
                `}
              >
                <div className="admin-generated-title">Tailored, Evidence-based Wellness Solutions</div>
                <div className="admin-generated-slogan">
                  Engage in personalized health assessments and dynamic activities to enhance your body's resilience and
                  vitality.
                </div>
              </div>
              {/* =====ACTION PLAN===== */}
              <div className="dashboard-action-plan">
                <div className="action-plan-grid">
                  <div
                    className="ap-row"
                    css={css`
                      .ap-button button {
                        background-color: ${brand?.color_primary};
                      }
                    `}
                  >
                    <div className="bg">
                      <img src={actionPlanBg} alt="background" />
                    </div>
                    <div className="ap-box box-format">
                      <Link to={`/${company_path}/challenge`}>
                        <div className="ap-icon">
                          <img src={generateURL(homepage?.challenges_icon)} alt="icon" className="icon-img" />
                        </div>
                        <div className="ap-title">Challenges</div>
                        <div className="ap-text">
                          Push yourself with our latest challenges. Test your limits and unlock your potential.
                        </div>
                        <div className="ap-button">
                          <button className="effect effect-5">Take The Challenge</button>
                        </div>
                      </Link>
                    </div>
                    <div className="ap-box box-format">
                      <Link to={`/${company_path}/activity`}>
                        <div className="ap-icon">
                          <img src={generateURL(homepage?.activities_icon)} alt="icon" className="icon-img" />
                        </div>
                        <div className="ap-title">Activities</div>
                        <div className="ap-text">
                          Stay active and inspired with our diverse activities. Discover new passions!
                        </div>
                        <div className="ap-button">
                          <button className="effect effect-5">Join An Activity</button>
                        </div>
                      </Link>
                    </div>
                    <div className="ap-box box-format">
                      <Link to={`/${company_path}/resources`}>
                        <div className="ap-icon">
                          <img src={generateURL(homepage?.resources_icon)} alt="icon" className="icon-img" />
                        </div>
                        <div className="ap-title">Resources</div>
                        <div className="ap-text">
                          Access valuable resources for personal and professional growth. Empower yourself!
                        </div>
                        <div className="ap-button">
                          <button className="effect effect-5">Explore Resources</button>
                        </div>
                      </Link>
                    </div>
                    <div className="ap-box box-format">
                      <Link
                        to={typeof myHRA === "undefined" ? `/${company_path}/hra` : `/${company_path}/hraresults`}
                        // to={`/${company_path}/hraresults`}
                      >
                        <div className="ap-icon">
                          <img src={generateURL(homepage?.hra_icon)} alt="icon" className="icon-img" />
                        </div>
                        <div className="ap-title">Health Risk Assessment</div>
                        <div className="ap-text">
                          Assess health risks and improve your well-being with personalized recommendations.
                        </div>
                        <div className="ap-button">
                          <button className="effect effect-5">Assess Your Health</button>
                        </div>
                      </Link>
                    </div>
                    <div className="ap-box box-format">
                      <Link to={`/${company_path}/contact`}>
                        <div className="ap-icon">
                          <img src={generateURL(homepage?.contact_icon)} alt="icon" className="icon-img" />
                        </div>
                        <div className="ap-title">Contact Us</div>
                        <div className="ap-text">
                          Reach out for assistance or with any questions. We’re here to help!
                        </div>
                        <div className="ap-button">
                          <button className="effect effect-5">Get In Touch</button>
                        </div>
                      </Link>
                    </div>
                    <div className="ap-box box-format">
                      <Link to={`/${company_path}/forums`}>
                        <div className="ap-icon">
                          <img src={generateURL(homepage?.chat_icon)} alt="icon" className="icon-img" />
                        </div>
                        <div className="ap-title">Chat</div>
                        <div className="ap-text">
                          Engage in lively discussions and connect with others. Share your thoughts and ideas!
                        </div>
                        <div className="ap-button">
                          <button className="effect effect-5">Join the Conversation</button>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <EmployerSpace />
              </div>
            </div>
          </div>
        </div>
      </div>
      {isPlay && <div className="done-annimation">{randomLottie()}</div>}
    </div>
  );
};

export default Dashboard;
