import React from "react";
import "./report.scoped.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFlag } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { useCreate } from "@pankod/refine-core";
import { NotificationType } from "types/notification";

library.add(far, faFlag);

interface ReportButtonProps {
  post_id?: any;
  message_id?: any;
  user_id?: string;
  content?: string;
  reporter_id?: string;
}

const ReportButton: React.FC<ReportButtonProps> = ({ message_id, user_id, content, post_id, reporter_id }) => {
  const { mutateAsync: createReport, isLoading } = useCreate();
  const handleReport = () => {
    createReport({
      resource: "notification",
      values: {
        user_id,
        message_id,
        post_id,
        type: message_id ? NotificationType.MessageReport : NotificationType.PostReport,
        content,
        reporter_id,
      },
      successNotification: {
        type: "success",
        message: "Report successfully created",
        description: "Success",
      },
    });
  };

  return (
    <button className="report-button" onClick={handleReport} disabled={isLoading}>
      {" "}
      <FontAwesomeIcon icon={"flag"} className="fa-flag" /> Report
    </button>
  );
};

export default ReportButton;
