import React, { useState, useEffect } from "react";
import "./account.scoped.scss";
import { useOne, useUpdate } from "@pankod/refine-core";
import { useMe } from "context/MeContext";
import EdiText from "react-editext";
import styled from "styled-components";
import { IUserUpdate } from "types/user";
import dayjs from "dayjs";
import { optionsGender } from "../Auth/SocialRegister/SocialRegister";
import { Select, Popconfirm } from "antd";
import { UploadImage } from "components/Upload/Upload";
import { Button, Form, Input, SaveButton, useSelect } from "@pankod/refine-antd";
import { config } from "config";
import { Checkbox } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { css } from "@emotion/react";
import { useCompany } from "context/CompanyContext";

dayjs.extend(customParseFormat);
const StyledEdiText = styled(EdiText)`
  .react-answer-1 {
    margin: 0 !important;
    align-items: center !important;
    text-align: center;
  } 
  button {
    border-radius: 5px; 
  }
  button[editext="edit-button"] {
    color: #000;
    width: 50px;
  }
  button[editext="save-button"] {
    background: rgb(240,240,240);
    margin: 0 10px 0 5px;
    &:hover {
      cursor:pointer;
      background: rgb(210,210,210,0.7);
    }
  }
  button[editext="cancel-button"] {
    background: rgb(240,240,240);
    &:hover {
      cursor:pointer;
      background: rgb(210,210,210,0.7);
    }
  }
  input, textarea {
    border-radius: 5px;
    border: 1px solid var(--maingreen);
    padding: 10px;
  }
  div[editext="view-container"], div[editext="edit-container"] {
    display: flex;
    justify-content: space-between
  }
  input.attrs(() => ({
    type: "Select",
  }))
`;

const MyAccount: React.FC = () => {
  const [isEditingGender, setIsEditingGender] = React.useState(false);
  const [gender, setGender] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { me: user, company } = useMe();
  const {hra_summary} = useCompany();
  const [employeeDetails, setEmployeeDetails] = useState(true);

  const { data, refetch } = useOne({
    resource: "users",
    id: user?.id!,
    queryOptions: {
      enabled: Boolean(user?.id),
    },
  });

  const { mutateAsync: updateUser, isLoading } = useUpdate();
  const [formInfo] = Form.useForm();
  const userData = data?.data;
  async function onSave(data: IUserUpdate) {
    await updateUser({
      resource: "users",
      id: user?.id!,
      values: data,
    });
    return (
      <Popconfirm
        title="Delete the task"
        description="Are you sure to delete this task?"
        okText="Yes"
        cancelText="No"
      ></Popconfirm>
    );
  }

  const icon = user?.isChangedIcon ? config.ASSET_URL + user?.icon : user?.icon;

  const iconData = Form.useWatch("icon", form);

  const onChange = async (e: CheckboxChangeEvent) => {
    await updateUser({
      resource: "users",
      id: user?.id!,
      values: {
        hideMeDashboard: e.target.checked,
      },
    });
  };
  const onChangeChart = async (e: CheckboxChangeEvent) => {
    await updateUser({
      resource: "users",
      id: user?.id!,
      values: {
        user_chart_status: e.target.checked,
      },
    });
  };
  console.log("user", userData)

  const { selectProps: selectPropsLocation } = useSelect({
    resource: "location",
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "company_id",
        operator: "eq",
        value: company?.id,
      },
    ],
    queryOptions: {
      enabled: Boolean(company?.id),
    },
    pagination: {
      pageSize: 100,
    },
  });

  const { selectProps: selectPropsTeam } = useSelect({
    resource: "team-company",
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "company_id",
        operator: "eq",
        value: company?.id,
      },
    ],
    queryOptions: {
      enabled: Boolean(company?.id),
    },
    pagination: {
      pageSize: 100,
    },
  });

  const { selectProps: selectPropsJobTitle } = useSelect({
    resource: "job-title",
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "company_id",
        operator: "eq",
        value: company?.id,
      },
    ],
    queryOptions: {
      enabled: Boolean(company?.id),
    },
    pagination: {
      pageSize: 100,
    },
  });

  const { selectProps: selectPropsEmployeeType } = useSelect({
    resource: "employee-type",
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "company_id",
        operator: "eq",
        value: company?.id,
      },
    ],
    queryOptions: {
      enabled: Boolean(company?.id),
    },
    pagination: {
      pageSize: 100,
    },
  });

  const professional_info = [
    {
      title: "Location",
      props: selectPropsLocation,
      placeholder: "Please choose your location",
      name: ["location", "id"],
      defaultField: "location_id",
    },
    {
      title: "Team",
      props: selectPropsTeam,
      placeholder: "Please choose your team",
      name: ["team_company", "id"],
      defaultField: "team_company_id",
    },
    {
      title: "Employee type",
      props: selectPropsEmployeeType,
      placeholder: "Please choose your employee type",
      name: ["employee_type", "id"],
      defaultField: "employee_type_id",
    },
    {
      title: "Job title",
      props: selectPropsJobTitle,
      placeholder: "Please choose your job title",
      name: ["job_title", "id"],
      defaultField: "job_title_id",
    },
  ];

  useEffect(() => {
    if (selectPropsEmployeeType?.options?.length === 0
        && selectPropsJobTitle?.options?.length === 0
        && selectPropsLocation?.options?.length === 0
        && selectPropsTeam?.options?.length === 0) {
        setEmployeeDetails(true);
      } else {
        setEmployeeDetails(false);
      }
  }, [
      selectPropsEmployeeType?.options?.length, 
      selectPropsJobTitle?.options?.length, 
      selectPropsLocation?.options?.length, 
      selectPropsTeam?.options?.length
  ]);

  return (
    <div className="account-page container pageload">
      <h2>Accounts Center</h2>
      <div className={`${employeeDetails ? "a" : "b"} content`}>
        <div className="left">
          <h3>Profile</h3>
          <div className="hr"></div>
          <div className="description-group container-flex">
            <p className="name">Avatar</p>
            {user && (
              <Form
                form={form}
                className="flex items-center"
                initialValues={{
                  icon: user.icon,
                }}
                onFinish={async () => {
                  await updateUser({
                    resource: "users",
                    id: user.id,
                    values: {
                      icon: iconData,
                      isChangedIcon: true,
                    },
                  });
                }}
              >
                <Form.Item hidden name="icon">
                  <Input />
                </Form.Item>
                <UploadImage
                  setLoading={setLoading}
                  loading={isLoading || loading}
                  isDefault={false}
                  form={form}
                  url={icon || ""}
                />
                {iconData !== user?.icon && (
                  <Button type="text" htmlType="submit">
                    Save
                  </Button>
                )}
              </Form>
            )}
          </div>
          <div className="hr"></div>
          <div className="description-group container-flex">
            <p className="name">First name</p>
            <p>
              <StyledEdiText
                type="text"
                inputProps={{
                  className: "textarea",
                  placeholder: "Please enter your first name ",
                  style: {
                    minWidth: "60%",
                  },
                  disabled: isLoading,
                  rows: 5,
                }}
                viewProps={{
                  className: "react-answer-1",
                  style: { margin: "0 0 10px 0" },
                }}
                saveButtonContent={<strong className="res">Update</strong>}
                cancelButtonContent={<strong className="res">Cancel</strong>}
                value={userData?.firstname}
                onSave={(val: string) => onSave({ firstname: val })}
              />
            </p>
          </div>
          <div className="hr"></div>
          <div className="description-group container-flex">
            <p className="name">Last name</p>
            <p className="value">
              <StyledEdiText
                type="text"
                inputProps={{
                  className: "textarea",
                  placeholder: "Please enter your last name",
                  style: {
                    minWidth: "60%",
                  },
                  rows: 5,
                }}
                viewProps={{
                  className: "react-answer-1",
                  style: { margin: "0 0 10px 0" },
                }}
                saveButtonContent={<strong className="res">Update</strong>}
                cancelButtonContent={<strong className="res">Cancel</strong>}
                value={userData?.lastname}
                onSave={(val: string) => onSave({ lastname: val })}
              />
            </p>
          </div>
          <div className="hr"></div>
          <div className="description-group container-flex">
            <p className="name">Email</p>
            <p className="value">{userData?.email}</p>
          </div>
          <div className="hr"></div>
          <div className="description-group container-flex">
            <p className="name">Employer Code</p>
            <p className="value">
              <p>{userData?.employeeCode}</p>
            </p>
          </div>
          <h3>Account</h3>
          <div className="hr"></div>
          <div className="description-group container-flex">
            <p className="name">Username</p>
            <p className="value">
              <StyledEdiText
                type="text"
                inputProps={{
                  className: "textarea",
                  placeholder: "Please enter your username",
                  style: {
                    minWidth: "60%",
                  },
                  rows: 5,
                }}
                viewProps={{
                  className: "react-answer-1",
                  style: { margin: "0 0 10px 0" },
                }}
                saveButtonContent={<strong className="res">Update</strong>}
                cancelButtonContent={<strong className="res">Cancel</strong>}
                value={userData?.username}
                onSave={(val: string) => onSave({ username: val })}
              />
            </p>
          </div>
          <div className="hr"></div>

          <div className="description-group container-flex">
            <p className="name">Date of birth</p>
            <p className="value">
              <StyledEdiText
                type="date"
                inputProps={{
                  className: "textarea",
                  placeholder: "Please enter your date of birth",
                  style: {
                    minWidth: "60%",
                  },
                  rows: 5,
                }}
                viewProps={{
                  className: "react-answer-1",
                  style: { margin: "0 0 10px 0" },
                }}
                saveButtonContent={<strong className="res">Update</strong>}
                cancelButtonContent={<strong className="res">Cancel</strong>}
                value={dayjs(userData?.dateOfBirth).format("MM/DD/YYYY")}
                // onSave={(val: string) => onSave({ dateOfBirth: new Date(val) })}
                onSave={(val: string) => onSave({ dateOfBirth: new Date(val) })}
              />
            </p>
          </div>
          <div className="hr"></div>
          <div className="description-group flex ">
            <p className="name w-[30%]">Birth Sex</p>
            <div className="w-[70%] flex items-end justify-end">
              {isEditingGender && (
                <Select
                  onChange={(val) => {
                    setGender(val);
                  }}
                  className="w-full "
                  size="large"
                  defaultValue={userData?.gender}
                  options={optionsGender}
                  placeholder={"Please enter your gender"}
                />
              )}
              <p className={`value ${isEditingGender ? "" : "w-full"}`}>
                <StyledEdiText
                  type="text"
                  inputProps={{
                    className: `textarea ${isEditingGender ? "hidden" : ""}`,
                    placeholder: "Please enter your gender",
                    style: {
                      minWidth: "60%",
                    },
                    rows: 5,
                    type: "checkbox",
                  }}
                  viewProps={{
                    className: "react-answer-1",
                    style: { margin: "0 0 10px 0" },
                  }}
                  saveButtonContent={<strong className="res">Update</strong>}
                  cancelButtonContent={<strong className="res">Cancel</strong>}
                  onCancel={() => {
                    setIsEditingGender(false);
                  }}
                  value={userData?.gender}
                  onSave={() => {
                    gender && onSave({ gender });
                    setIsEditingGender(false);
                  }}
                  onEditingStart={() => {
                    setIsEditingGender(true);
                  }}
                />
              </p>
            </div>
          </div>
          <div className="hr"></div>
          <div className="description-group flex ">
            <p className="name w-[30%]">Hide me from Leaderboard</p>
            <p className="value">
              <Checkbox checked={userData?.hideMeDashboard} onChange={onChange}></Checkbox>
            </p>
          </div>
          <div className="description-group flex ">
            <p className="name w-[30%]">user_chart_status</p>
            <p className="value">
              <Checkbox checked={userData?.user_chart_status} onChange={onChangeChart}></Checkbox>
            </p>
          </div>
        </div>
               
        {userData && (
          <div className={`${employeeDetails ? "none" : "right"}`}>
            <h3>Employee details</h3>
            <Form
              form={formInfo}
              onFinish={async (e) => {
                await updateUser({
                  resource: "users",
                  id: user?.id!,
                  values: e,
                });
                refetch();
              }}
              initialValues={{
                location: {
                  id: userData.location_id || "",
                },
                team_company: {
                  id: userData.team_company_id || "",
                },
                job_title: {
                  id: userData.job_title_id || "",
                },
                employee_type: {
                  id: userData.employee_type_id || "",
                },
              }}
            >
              {professional_info.map((item) => {
                //console.log("item", item);
                return item?.props?.options?.length === 0 ? null : (
                  <Form.Item key={item.title} name={item.name}>
                    <div>
                      <div className="hr"></div>
                      <div className="description-group container-flex">
                        <p className="name">{item.title}</p>
                        <p className="value">
                          <Select
                            showSearch
                            filterSort={(optionA: any, optionB: any) =>
                              (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
                            }
                            {...item.props}
                            className="select"
                            defaultValue={userData[item.defaultField]}
                            placeholder={item.placeholder}
                            onChange={(e: any) => {
                              formInfo.setFieldValue(item.name, e);
                            }}
                          />
                        </p>
                      </div>
                    </div>
                  </Form.Item>
                );
              })}
              <Button onClick={formInfo.submit} css={css`
                display: flex !important;
                float: right !important;
                background-color: ${hra_summary?.main_hra_color} !important;
                color: white !important;
                border-radius: 10px !important;
                padding: 5px 20px !important;
                height: 100%;
                &:hover {
                  color: ${hra_summary?.main_hra_color} !important;
                  background-color: ${hra_summary?.title_color} !important;
                }
              `}
              >Save</Button>
            </Form>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyAccount;
